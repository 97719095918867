import React from "react";
import { useNavigate } from "react-router-dom";
import { BsCalendar3 } from "react-icons/bs";
import { IoIosLink } from "react-icons/io";
import { MdOutlineSchedule } from "react-icons/md";

const Welcome1 = () => {
	const navigate = useNavigate();
	return (
		<div>
			<div className='flex h-screen'>
				<div className='flex w-[60%] h-[90%] justify-center items-center bg-yellow-50 mt-10 ml-10'>
					<div className='grid w-[70%] text-left'>
						<div className='pb-10 '>
							<h1 className=' text-4xl font-semibold pb-3'>
								Easily switch context between meetings & work.
							</h1>
							<p className='text-sm text-gray-400 mb-12'>
								you're minutes away from saving time and maximizing your day.
							</p>
							<p className='mt-4 pt-1'>
								Tabin, is an context switching app that is designed to enhance
								your productivity and streamline your workday{" "}
							</p>
						</div>
						<div className='bg-white'>
							<div className='py-6 px-6'>
								<p className='flex'>
									<i className='flex justify-center items-center text-xl mr-2'>
										<BsCalendar3 />
									</i>
									Connect your calendar
								</p>
								<p className='flex'>
									<i className='flex justify-center items-center text-xl mr-2'>
										<IoIosLink />
									</i>
									Create a Tabin for each meeting
								</p>
								<p className='flex'>
									<i className='flex justify-center items-center text-xl mr-2'>
										<MdOutlineSchedule />
									</i>
									Schedule Tabin at the start of the meeting
								</p>
							</div>
						</div>

						<button
							className='flex justify-center w-[60%] border border-b-2 rounded-full bg-green-300 text-white py-3 px-5 hover:bg-green-600 mt-[130px]'
							onClick={() => {
								navigate("/welcome-2");
							}}
						>
							Continue
						</button>
					</div>
				</div>
				<div className='w-[40%] h-[90%] bg-yellow-100 mt-10 mr-10'>
					<div className='flex h-[100%] justify-center items-center'>
						<img src='multiple_windows.svg' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Welcome1;
