import React, { useEffect, useState } from "react";
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FcCalendar } from "react-icons/fc";
import { getUserTabins } from "../features/tabin/tabinSlice";

const TAG = "CreateTabinsFromCalendarEvents";

const Welcome3 = () => {
	const user = useSelector((state) => state.auth.user);
	const [events, setEvents] = useState([]);
	const [currentEventIndex, setCurrentEventIndex] = useState(0);
	const [finished, setFinished] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleNext = () => {
		if (currentEventIndex < events.length - 1) {
			setCurrentEventIndex(currentEventIndex + 1);
		} else {
			setFinished(true);
		}
	};

	useEffect(() => {
		let gcalevents = JSON.parse(Cookie.get("_gceve"));
		setEvents(gcalevents);
		console.log("gcalevents", gcalevents);
	}, []);

	useEffect(() => {
		if (finished) {
			dispatch(getUserTabins(user.google_id)); // Ensure `user` is defined
			// navigate("/schedule");
			Cookie.remove("_gceve");
			// const timer = setTimeout(() => {
			// 	navigate("/schedule");
			// }, 3000);
			// return () => clearTimeout(timer);
		}
	}, [finished, navigate, dispatch, user]);

	return (
		<div className='flex w-full h-screen'>
			<div className='flex-row w-[60%] h-[90%] justify-center items-center mt-10 ml-10 bg-yellow-50'>
				{events.length > 0 && !finished ? (
					<div className=''>
						<div>
							<p className='text-3xl mt-10 '>Event #{currentEventIndex + 1}</p>

							<p className='text-gray-500 mt-2'>
								Let's create a Tabin for your event:{" "}
							</p>
						</div>
						<div className='flex h-auto w-full justify-center items-center mt-[70px]'>
							<div className='flex h-full w-auto justify-center items-center border border-b-2 p-14 bg-white'>
								<i className=' text-6xl mr-1'>
									<FcCalendar />
								</i>
								<h1 className='justify-center items-center text-4xl ml-5  text-green-700'>
									{events[currentEventIndex].summary}
								</h1>
							</div>
						</div>
						<ul className='grid mt-[50px] list-decimal justify-center items-center text-left'>
							<li className='pb-5'>
								Open all the URLs you would need for your event
							</li>
							<li className=''>
								Click on Tabin Extension icon
								<div className='h-auto w-[200px] mt-2'>
									<img
										src='tabin_ext_toolbar.png'
										alt='Context Switching App to increase work productivity, schedule tabins and get notification, single link to multiple links, take notes while attending meetings, save time and increase focus'
									/>
								</div>
							</li>

							<li className='pt-5'>
								Give the Tabin a name and click create
								<div className='h-auto w-[200px] mt-2'>
									<img
										src='create_tabin.jpeg'
										alt='Context Switching App to increase work productivity, schedule tabins and get notification, single link to multiple links, take notes while attending meetings, save time and increase focus'
									/>
								</div>
							</li>
						</ul>
						<button
							className='justify-center items-center w-[60%] border border-b-2 rounded-full bg-green-300 text-white py-3 px-5 hover:bg-green-600 mt-[100px]'
							onClick={handleNext}
						>
							Next
						</button>
						<p className='text-gray-500 mt-10'>Total Events: {events.length}</p>
					</div>
				) : (
					<div className='grid h-full w-full justify-center items-center text-left'>
						<div className='pb-10 '>
							<h1 className=' text-4xl font-semibold pb-3'>
								You have completed creating Tabins.
							</h1>
							<p className='text-sm text-gray-400 mb-12'></p>
							<p className='mt-4 pt-1'>
								Now lets schedule your Tabins to the respective meeting times
							</p>
						</div>
						<div className='bg-white'>
							<div className='py-6 px-6'>
								<p className='flex'>
									<i className='flex justify-center items-center text-xl mr-2'></i>
									Schedule Tabin to the start of the meeting.
								</p>
								<p className='flex'>
									<i className='flex justify-center items-center text-xl mr-2'></i>
									Recieve a Tabin chrome notification.
								</p>
								<p className='flex'>
									<i className='flex justify-center items-center text-xl mr-2'></i>
									Click on the notification and open Tabin.
								</p>
							</div>
						</div>

						<button
							className='justify-center items-center w-[60%] border border-b-2 rounded-full bg-green-300 text-white py-3 px-5 hover:bg-green-600 mt-[50px]'
							onClick={() => {
								navigate("/schedule");
							}}
						>
							Continue
						</button>
					</div>
				)}
			</div>
			<div className='w-[40%] h-[90%] bg-yellow-100 mt-10 mr-10'>
				<div className='flex h-[100%] justify-center items-center'>
					<img className='h-[600px]' src='rocket_man.svg' />
				</div>
			</div>
		</div>
	);
};

export default Welcome3;
