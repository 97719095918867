import React, { useEffect, useState } from "react";
import Cookie from "js-cookie";
import { gapi } from "gapi-script";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GoogleLogin } from "react-google-login";
import { FcCheckmark } from "react-icons/fc";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CALENDAR_CLIENTID;
//const API_KEY = process.env.REACT_APP_GOOGLE_CALENDAR_API_KEY;
const DISCOVERY_DOCS = [
	"https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
];
const SCOPES = "https://www.googleapis.com/auth/calendar.readonly";

const TAG = "ConnectGoogleCalendar";

const Welcome2 = () => {
	const navigate = useNavigate();

	const [events, setEvents] = useState([]);
	const [user, setUser] = useState({});

	const onSuccess = async (res) => {
		console.log(TAG + ".onSuccess");
		console.log("LOGIN SUCCESS! current user: ", res);
		setUser(res.profileObj);

		const accessToken = res.tokenObj.access_token;

		// Get tomorrow's date in the required format (ISO 8601)
		const tomorrow = new Date();
		tomorrow.setDate(tomorrow.getDate() + 1);
		const startOfDay = new Date(tomorrow.setHours(0, 0, 0, 0)).toISOString();
		const endOfDay = new Date(tomorrow.setHours(23, 59, 59, 999)).toISOString();

		// Fetch calendar events for tomorrow
		console.log("hello before");
		try {
			const eventsResponse = await fetch(
				`https://www.googleapis.com/calendar/v3/calendars/primary/events?timeMin=${startOfDay}&timeMax=${endOfDay}&singleEvents=true&orderBy=startTime`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				}
			);
			const eventsData = await eventsResponse.json();
			setEvents(eventsData.items); // Set eventsData.items, not eventsData
			Cookie.set("_gceve", JSON.stringify(eventsData.items));
			console.log("Tomorrow's events: ", eventsData.items);

			//navigate("/welcome-3");

			// After fetching events, mark user as signed in to remove Google button
		} catch (error) {
			console.error("Error fetching calendar events: ", error);
		}

		console.log("hello after");
	};

	const onFailure = (res) => {
		// let cookie_distinct_id = Cookie.get("distinct_id");
		// Mixpanel.track("User Login failed", {
		// 	user: cookie_distinct_id,
		// });
		console.log("LOGIN Failed! current user: ", res);
	};

	return (
		<div className='flex h-screen'>
			<div className='flex w-[60%] h-[90%] justify-center items-center  bg-yellow-50 mt-10 ml-10'>
				<div className='grid mt-[150px]'>
					{user && user.email ? (
						<>
							<div className='pb-10'>
								<h1 className='text-4xl font-semibold pb-3'>
									Your calendar is connected
								</h1>
								<p>
									This is the primary calendar that has your meetings for
									tomorrow.
								</p>
							</div>
							<div className='h-full'>
								<div className='flex h-[100px] w-full border border-b-2 rounded-xl bg-white text-black p-5 mb-[200px]'>
									<i className='flex justify-center items-center text-2xl mr-2'>
										<FcCheckmark />
									</i>
									<img className='rounded-full ml-3' src={user.imageUrl}></img>
									<p className='flex justify-center items-center ml-4 text-xl font-medium'>
										{user.email}
									</p>
								</div>

								<button
									className='w-[60%] border border-b-2 rounded-full bg-green-300 text-white py-3 px-5 hover:bg-green-600 '
									onClick={() => {
										navigate("/welcome-3");
									}}
								>
									Continue
								</button>
							</div>
						</>
					) : (
						<>
							<div className='pb-10'>
								<h1 className='text-4xl font-semibold pb-3'>
									Connect Your Work Calendar
								</h1>
								<p>
									This is the primary calendar that has your meetings for
									tomorrow.
								</p>
							</div>
							<GoogleLogin
								clientId={CLIENT_ID}
								render={(renderProps) => (
									<button
										className='googlebtn justify-center'
										onClick={renderProps.onClick}
										disabled={renderProps.disabled}
									>
										<span className='googlelogowhitebox'>
											<img
												className='googlelogo'
												src='google_logo.png'
												alt='Context Switching App to increase work productivity, schedule tabins and get notification, single link to multiple links, take notes while attending meetings, save time and increase focus'
											/>
										</span>
										CONNECT WORK CALENDAR
									</button>
								)}
								buttonText='Connect Google Calendar'
								onSuccess={onSuccess}
								onFailure={onFailure}
								cookiePolicy={"single_host_origin"}
								isSignedIn={false}
								discoveryDocs={DISCOVERY_DOCS}
								scope={SCOPES}
							/>
						</>
					)}
				</div>
			</div>
			<div className='w-[40%] h-[90%] bg-yellow-100 mt-10 mr-10'>
				<div className='flex h-[100%] justify-center items-center'>
					<img src='calendar.svg' />
				</div>
			</div>
		</div>
	);
};

export default Welcome2;
